
import img from '../../media/builders.png';
const Construction = () => {

    return (
        <div className="flex flex-col items-center justify-center max-[440px]:h-[32rem] max-[819px]:h-[30.2rem]">
            <figure>
                <img
                    src={img}
                    className="h-[20rem] w-[30rem] max-[500px]:w-[20rem] max-[500px]:h-[10rem]"
                    alt="An illustration of a construction site"
                />
            </figure>

            <h2 className="max-[620px]:text-[2rem] text-center text-[3rem]">
                Under Construction
            </h2>
            <hr className="w-[50%] max-[620px]:w-[90%] border-[#D9D9D9] my-4"/>
            <p className="text-center mx-[2rem] max-[620px]:text-[1rem]">
                This page is under construction
            </p>
            <p className="text-center mx-[2rem] max-[620px]:text-[1rem]">
                Please stay tuned for more information!
            </p>
        </div>
    );

};


export default Construction;
